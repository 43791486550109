import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // 用户信息
    usercenter: {
      true_name: '',
      photo: '',
      mobile: '',
      is_free_send: ''
    },
    // 菜单下标
    menuIndex: 0,
    // 消息列表 数量
    wsUnreadCount: {
      num: 0,
      arr: []
    },
    // 接受的消息内容
    wsUnreadData: null,
    // 发送消息
    messageSending: "",
    // 进度条
    progress: {}

  },
  mutations: {
    // 赋值用户信息存储  同步操作
    setUserInfo(state, val) {
      for (let i in val) {
        state.usercenter[i] = val[i];
      }
    },
    // 消息列表 数量
    msessageConent(state, obj) {
      state.wsUnreadCount.num = obj.num;
      state.wsUnreadCount.arr = obj.arr;
    },
    msessageObj(state, data) {
      state.wsUnreadData = data;
    },
    messageSending(state, data) {
      state.messageSending = data;
    },
    progressInfo(state, obj) {
      state.progress = obj;
    }
  },
  actions: {
    changeUserInfo: ({ commit }, val) => {
      commit('setUserInfo', val);
    },
    // 清理登录信息 但保留记住的帐号密码
    clearLoginInfo() {
      let userInfo = localStorage.userInfo; //保存登录的账号密码
      let remember = localStorage.remember;
      // localStorage.clear();
      localStorage.userInfo = userInfo;
      localStorage.remember = remember;
    },
    msessageMethod(con, value) {
      con.commit("msessageConent", value)
    },
    msessageData(con, value) {
      con.commit("msessageObj", value)
    }
  },
})
